import * as React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { observer } from 'mobx-react';
import { CssBaseline } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useBrowserTitle } from '../shared/hooks/useBrowserTitle';
import { AppRouter } from '../core/router';
import { injectTSDI } from '../core/tsdi';
import '../core/window';
import './app.styles.scss';
import { ErrorBoundary } from './error-boundary';
import { theme } from './app-theme';

function getComponent(path: string) {
    const router = injectTSDI(AppRouter);
    const link = router.getLink(path);

    if (link) {
        const route = router.getRoute(link);

        if (route) {
            return route.Component || null;
        }
    }

    return null;
}

function getComponentProps() {
    const { route, params } = injectTSDI(AppRouter);
    const id = params.id ? parseInt(params.id, 10) : undefined;
    const defaultProps = { id };

    switch (route) {
        default:
            return defaultProps;
    }
}

const RouteContent = observer(() => {
    const { route } = injectTSDI(AppRouter);

    const { Component, componentProps } = React.useMemo(
        () => ({
            Component: getComponent(route),
            componentProps: getComponentProps()
        }),
        [route]
    );

    return (
        <React.Suspense fallback={<div />}>
            {Component ? (
                <div>
                    <Component {...componentProps} />
                </div>
            ) : null}
        </React.Suspense>
    );
});

export const App = observer(() => {
    useBrowserTitle('Home');

    return (
        <ErrorBoundary>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <ToastContainer />
                <main>
                    <RouteContent />
                </main>
            </ThemeProvider>
        </ErrorBoundary>
    );
});
