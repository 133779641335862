/* eslint-disable prefer-rest-params */
/* eslint-disable no-undef */
window.dataLayer = window.dataLayer || [];
function gtag() {
    dataLayer.push(arguments);
}
window.gtag = gtag;
gtag('js', new Date());

gtag('config', 'AW-608936073'); // adwords
gtag('config', process.env.MAGICLINE_ONBOARDING_TRACKING_ID); // Universal Analytics
gtag('config', process.env.TRY_MAGICLINE_GA4_MEASUREMENT_ID); // GA4
gtag('config', process.env.MAGICLINE_WEBSITE_GA4_MEASUREMENT_ID); // GA4

gtag('event', 'conversion', {
    send_to: 'AW-608936073/Dc-tCPTctecCEInBrqIC'
});
